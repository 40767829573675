var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-2" },
    [
      _c("InboxFilter", {
        attrs: { amount: 2, filterName: "READ" },
        model: {
          value: _vm.filter_read,
          callback: function ($$v) {
            _vm.filter_read = $$v
          },
          expression: "filter_read",
        },
      }),
      _vm.functionbox_uuid != ""
        ? _c(
            "span",
            [
              _c("InboxFilter", {
                attrs: { amount: 3, filterName: "ASSIGNED" },
                model: {
                  value: _vm.filter_assigned,
                  callback: function ($$v) {
                    _vm.filter_assigned = $$v
                  },
                  expression: "filter_assigned",
                },
              }),
              _c("InboxFilter", {
                attrs: { amount: 2, filterName: "STATUS" },
                model: {
                  value: _vm.filter_status,
                  callback: function ($$v) {
                    _vm.filter_status = $$v
                  },
                  expression: "filter_status",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.functionbox_uuid == ""
        ? _c(
            "span",
            [
              _c("InboxFilter", {
                attrs: { amount: 2, filterName: "ARCHIVED" },
                model: {
                  value: _vm.filter_archived,
                  callback: function ($$v) {
                    _vm.filter_archived = $$v
                  },
                  expression: "filter_archived",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showFilterClearAll
        ? _c(
            "b-button",
            {
              staticClass: "no-border",
              attrs: { size: "sm" },
              on: { click: _vm.clearFilterAll },
            },
            [_vm._v(_vm._s(_vm.$t("FILTER.CLEAR")))]
          )
        : _vm._e(),
      _vm.fields
        ? _c("b-table", {
            key: "inbox_" + _vm.redrawIndex,
            staticClass: "mt-2 ml-0 pl-0",
            attrs: {
              small: "",
              bordered: "",
              items: _vm.items,
              hover: "",
              selectable: "",
              "select-mode": "single",
              fields: _vm.fields,
              "tbody-tr-class": _vm.rowClass,
              busy: _vm.loading,
            },
            on: { "row-clicked": _vm.clickedRow },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(created_date)",
                  fn: function (data) {
                    return [
                      _vm._v(
                        _vm._s(_vm.FormatDateTime(data.item.created_date))
                      ),
                    ]
                  },
                },
                {
                  key: "cell(size)",
                  fn: function (data) {
                    return [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.bytesToSize(data.item.size)) +
                          "\n    "
                      ),
                    ]
                  },
                },
                {
                  key: "cell(from_email)",
                  fn: function (data) {
                    return [
                      data.item.message_type == "notification"
                        ? _c("span", [_vm._v("-")])
                        : _vm._e(),
                      data.item.message_type != "notification"
                        ? _c("span", [
                            data.item.from_name != ""
                              ? _c("span", [
                                  _vm._v(_vm._s(data.item.from_name)),
                                  data.item.from_email != ""
                                    ? _c("span", [
                                        _vm._v(
                                          ", " + _vm._s(data.item.from_email)
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            data.item.from_name == ""
                              ? _c("span", [
                                  _vm._v(_vm._s(data.item.from_email)),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "cell(has_attachment)",
                  fn: function (data) {
                    return [
                      _c("img", {
                        staticClass: "icon mr-1",
                        attrs: {
                          src: _vm.messageIcon(data.item.message_type),
                          alt: _vm.$t(
                            "MESSAGES.TYPE." + data.item.message_type
                          ),
                        },
                      }),
                      _c("MessageStatus", {
                        attrs: { status: data.item.status },
                      }),
                      _vm._v(" \n      "),
                      data.item.has_attachment
                        ? _c("i", { staticClass: "mr-1 fal fa-paperclip" })
                        : _vm._e(),
                      data.item.archived == 1 && _vm.functionbox_uuid && 1 == 0
                        ? _c("i", { staticClass: "fal fa-times-circle" })
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "cell(subject)",
                  fn: function (data) {
                    return [
                      _c("div", { staticClass: "text-break" }, [
                        _vm._v(
                          "\n        " + _vm._s(data.item.subject) + "\n      "
                        ),
                      ]),
                      _c("div", { staticClass: "hidden-not-sm" }, [
                        _vm._v("\n        " + _vm._s(_vm.$t("FROM")) + " "),
                        data.item.message_type == "notification"
                          ? _c("span", [_vm._v("-")])
                          : _vm._e(),
                        data.item.message_type != "notification"
                          ? _c("span", [
                              data.item.from_name != ""
                                ? _c("span", [
                                    _vm._v(_vm._s(data.item.from_name)),
                                    data.item.from_email != ""
                                      ? _c("span", [
                                          _vm._v(
                                            ", " + _vm._s(data.item.from_email)
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              data.item.from_name == ""
                                ? _c("span", [
                                    _vm._v(_vm._s(data.item.from_email)),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm.functionbox_uuid && data.item.claimed
                          ? _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("CLAIMED_BY")) +
                                  "  " +
                                  _vm._s(data.item.claimed) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  },
                },
                {
                  key: "cell(claimed)",
                  fn: function (data) {
                    return [
                      _vm._v("\n      " + _vm._s(data.item.claimed) + "\n    "),
                    ]
                  },
                },
                {
                  key: "cell(conversation_id)",
                  fn: function (data) {
                    return [
                      data.item.claimed
                        ? _c("span", [
                            data.item.claimed == _vm.user.information.email
                              ? _c("span", [
                                  _c("i", {
                                    staticClass: "fa-solid fa-circle green",
                                  }),
                                ])
                              : _vm._e(),
                            data.item.claimed != _vm.user.information.email
                              ? _c("span", [
                                  _c("i", {
                                    staticClass: "fa-solid fa-triangle blue",
                                  }),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "cell(to_email)",
                  fn: function (data) {
                    return [
                      data.item.claimed
                        ? _c("span", [
                            data.item.claimed == _vm.user.information.email
                              ? _c("span", [
                                  _c("i", {
                                    staticClass: "fa-solid fa-circle green",
                                  }),
                                ])
                              : _vm._e(),
                            data.item.claimed != _vm.user.information.email
                              ? _c("span", [
                                  _c("i", {
                                    staticClass: "fa-solid fa-triangle blue",
                                  }),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "cell(id)",
                  fn: function (data) {
                    return [
                      _c("img", {
                        staticClass: "icon mr-1",
                        attrs: {
                          src: _vm.messageIcon(data.item.message_type),
                          alt: _vm.$t(
                            "MESSAGES.TYPE." + data.item.message_type
                          ),
                        },
                      }),
                      _c("MessageStatus", {
                        attrs: { status: data.item.status },
                      }),
                      _vm._v(" \n      "),
                      data.item.has_attachment
                        ? _c("span", [
                            _c("i", { staticClass: "fal fa-paperclip" }),
                          ])
                        : _vm._e(),
                      _vm._v(
                        "\n      " + _vm._s(data.item.subject) + "\n      "
                      ),
                      _c("div", { staticClass: "time-information f12" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.FormatDateTime(data.item.created_date)) +
                            "\n      "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("FROM")) +
                            " " +
                            _vm._s(data.item.from_email) +
                            "\n      "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "table-busy",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center my-2" },
                        [_c("b-spinner", { staticClass: "align-middle" })],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1646692695
            ),
          })
        : _vm._e(),
      _c("b-pagination", {
        staticClass: "mt-2",
        attrs: {
          "total-rows": _vm.totalItems,
          "per-page": _vm.limit,
          align: "center",
          pills: "",
        },
        on: { input: _vm.getInbox },
        model: {
          value: _vm.currentPage,
          callback: function ($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }