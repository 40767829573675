<template>
  <section class="mt-2">

    <InboxFilter :amount=2 filterName="READ" v-model="filter_read"></InboxFilter>

    <span v-if="functionbox_uuid != ''">
      <InboxFilter :amount=3 filterName="ASSIGNED" v-model="filter_assigned"></InboxFilter>
      <InboxFilter :amount=2 filterName="STATUS" v-model="filter_status"></InboxFilter>
    </span>
    <span v-if="functionbox_uuid == ''">
      <InboxFilter :amount=2 filterName="ARCHIVED" v-model="filter_archived"></InboxFilter>
    </span>

    <b-button class="no-border" size="sm" v-if="showFilterClearAll" @click="clearFilterAll" >{{ $t("FILTER.CLEAR") }}</b-button>
    
    <b-table v-if="fields"
      :key="'inbox_' + redrawIndex"
      small
      bordered
      :items="items"
      hover
      selectable
      select-mode="single"
      class="mt-2 ml-0 pl-0"
      :fields="fields"
      :tbody-tr-class="rowClass"
      :busy="loading"
      @row-clicked="clickedRow"
    >
      <template v-slot:cell(created_date)="data">{{
        FormatDateTime(data.item.created_date)
      }}</template>

      <template v-slot:cell(size)="data">
        {{ bytesToSize(data.item.size) }}
      </template>

      <template v-slot:cell(from_email)="data">
        <span v-if="data.item.message_type == 'notification'">-</span>
        <span v-if="data.item.message_type != 'notification'"><span v-if="data.item.from_name != ''"
          >{{ data.item.from_name
          }}<span v-if="data.item.from_email != ''"
            >, {{ data.item.from_email }}</span
          ></span
        >
        <span v-if="data.item.from_name == ''">{{ data.item.from_email }}</span></span>
      </template>

      <template v-slot:cell(has_attachment)="data">
        <img class="icon mr-1" :src="messageIcon(data.item.message_type)" :alt="$t('MESSAGES.TYPE.' + data.item.message_type)"  />
        <MessageStatus :status="data.item.status"></MessageStatus>&nbsp;
        <i v-if="data.item.has_attachment" class="mr-1 fal fa-paperclip" />
        <i
          v-if="data.item.archived == 1 && functionbox_uuid && 1 == 0"
          class="fal fa-times-circle"
        />
      </template>

      
      <template v-slot:cell(subject)="data"> 
        <div class="text-break">
          {{ data.item.subject }}
        </div>
        <div class="hidden-not-sm">
          {{ $t('FROM') }} <span v-if="data.item.message_type == 'notification'">-</span>
          <span v-if="data.item.message_type != 'notification'"><span v-if="data.item.from_name != ''"
            >{{ data.item.from_name
            }}<span v-if="data.item.from_email != ''"
              >, {{ data.item.from_email }}</span
            ></span
          >
          <span v-if="data.item.from_name == ''">{{ data.item.from_email }}</span></span>
          <div v-if="functionbox_uuid && data.item.claimed">
            {{ $t('CLAIMED_BY') }}  {{ data.item.claimed }}
          </div>
        </div>
      </template>

      
      <template v-slot:cell(claimed)="data">
        {{ data.item.claimed }}
      </template>
      
      <template v-slot:cell(conversation_id)="data">
        <span  v-if="data.item.claimed">
          <span v-if="data.item.claimed == user.information.email"><i class="fa-solid fa-circle green"></i></span>
          <span v-if="data.item.claimed != user.information.email"><i class="fa-solid fa-triangle blue"></i></span>
        </span>
      </template>
    
      <template v-slot:cell(to_email)="data">
        <span v-if="data.item.claimed">
          <span v-if="data.item.claimed == user.information.email"><i class="fa-solid fa-circle green"></i></span>
          <span v-if="data.item.claimed != user.information.email"><i class="fa-solid fa-triangle blue"></i></span>
        </span>
      </template>



      <template v-slot:cell(id)="data">
        <img class="icon mr-1" :src="messageIcon(data.item.message_type)" :alt="$t('MESSAGES.TYPE.' + data.item.message_type)"  />
        <MessageStatus :status="data.item.status"></MessageStatus>&nbsp;
        <span v-if="data.item.has_attachment"
          ><i class="fal fa-paperclip"
        /></span>
        {{ data.item.subject }}
        <div class="time-information f12">
          {{
            FormatDateTime(data.item.created_date)
          }}
        </div>
        <p>
          {{ $t("FROM") }} {{ data.item.from_email }}
        </p>
      </template>
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
    </b-table>

    <b-pagination
      class="mt-2"
      v-model="currentPage"
      :total-rows="totalItems"
      :per-page="limit"
      align="center"
      @input="getInbox"
      pills
    >
    </b-pagination>
  </section>
</template>
<script>
import MessageStatus from "@/components/View/MessageStatus";
import InboxFilter from "@/components/Input/Filter/InboxFilter";
export default {
  components: { MessageStatus, InboxFilter },
  props: [
    "searchText",
    "limit",
    "functionbox_uuid",
    "update_message_uuid",
    "user_uuid",
  ],
  data() {
    return {
      loading: false,
      currentPage: 1,
      redrawIndex: 0,
      items: [],
      selectedIndex: 0,
      totalItems: 0,
      state: 1,
      archived: 0,
      filter_archived: ["0"],
      filter_assigned: ["0", "2"],
      filter_read: ["0", "1"],
      filter_status: ["0"]
    };
  },
  methods: {
    messageIcon(message_type) {
      return "/img/message_type/" + message_type + ".png";
    },
    clearFilterAll() {
      this.filter_read = [];
      if(this.functionbox_uuid != "")
      {
        this.filter_assigned = [];
        this.filter_status = [];
      }else{
        this.filter_archived = [];
      }
      this.getInbox();
    },
    rowClass(item, type) {
      if (item && type === "row") {        
        let returnClass = "";
        /*if (item.claimed != null) {
          if (this.functionbox_uuid != "") {
            if(this.user.information.email == item.claimed)
            {
              returnClass = "claimed";
            }else{
              returnClass = "claimed-others";
            }
          }
        }*/
        if (this.functionbox_uuid != "") {
          if (item.archived != 0) {
            returnClass = "archived";
          }
        }
        if (this.user_uuid != "") {
          if (item.archived != 0) {
            returnClass = "archived";
          }
        }
        if (item.fetched === 0) {
          returnClass += " not-viewed";
        }
        return returnClass;
      }
      return "";
    },
    clickedRow(data, index) {
      this.selectedIndex = index;
      if(data.message_type == 'notification')
      {
        this.$emit("viewingNotification", data);
      }else{
        this.$emit("viewingMessage", data.message_uuid);
      }
    },
    getInbox: function () {      
      let self = this;
      if(!this.loading)
      {
        this.loading = true;
        this.$http
          .post(this.user.hostname + "/inbox/list", {
            functionbox_uuid: this.functionbox_uuid,
            user_uuid: this.user_uuid,
            page: this.currentPage,
            limit: parseInt(this.limit),
            state: this.state,
            search_text: this.searchText,
            filter_status: this.filter_status,
            filter_assigned: this.filter_assigned,
            filter_archived: this.filter_archived,
            filter_read: this.filter_read,
          })
          .then((response) => {
            self.redrawIndex = self.redrawIndex + 1;
            self.loading = false;
            self.$emit("changeUpdateMessageUuid", "");
            self.items = response.data.items;
            self.totalItems = response.data.count;
          })
          .catch(() => {
            self.loading = false;
          });
        }
    },
    UpdateItem: function (message_uuid) {
      if (message_uuid != "") {
        let self = this;
        this.$http
          .post(this.user.hostname + "/inbox/message-update", {
            functionbox_uuid: this.functionbox_uuid,
            user_uuid: this.user_uuid,
            message_uuid: message_uuid,
          })
          .then((response) => {
            if (response.data.removed == 1 || response.data.removed == 1) {
              self.items.splice(self.selectedIndex, 1);
            } else {
              self.items[self.selectedIndex] = response.data;
            }
            self.redrawIndex = self.redrawIndex + 1;
          })
          .catch(() => {});
      }
    },
    HandleFilter(items, name)
    {
      if (this.functionbox_uuid != "") {
        localStorage.setItem(
          name + "_" + this.functionbox_uuid.replace(":", "_"),
          JSON.stringify(items)
        );
      }else{
        if (this.user_uuid != "") {
          localStorage.setItem(
            name + "_" + this.user_uuid.replace(":", "_"),
            JSON.stringify(items)
          );
        } else {
          localStorage.setItem(
            name,
            JSON.stringify(items)
          );
        }
      }
    },
    GetFilter(name, ifNull)
    {
      if (this.functionbox_uuid != "") { 
        if (
          localStorage.getItem( name + "_" + this.functionbox_uuid.replace(":", "_") ) !== null
        ) {
          return JSON.parse(
            localStorage.getItem(
              name + "_" + this.functionbox_uuid.replace(":", "_")
            )
          );
        }else{
          return ifNull;
        }
      }else{
        if (this.user_uuid != "") {
          if (
            localStorage.getItem(
              name + "_" + this.user_uuid.replace(":", "_")
            ) !== null
          ) {
            return JSON.parse(
              localStorage.getItem(
                name + "_" + this.user_uuid.replace(":", "_")
              )
            );
          }else{
            return ifNull;
          }
        } else {
          if (localStorage.getItem(name) !== null) {
            return JSON.parse(
              localStorage.getItem(name)
            );
          }else{
            return ifNull;
          }
        }
      }
    }
  },
  computed: {
    fields(){
      if(this.functionbox_uuid)
      {
        return [        
          {
            key: "conversation_id",
            label: "",
            class: "text-break w-20 text-center hidden-mobile",
            sortable: false,
          },
          {
            key: "to_email",
            label: "",
            class: "text-break w-20 text-center hidden-not-mobile",
            thClass: "hidden",
            sortable: false,
          },
          {
            key: "id",
            class: "hidden-not-mobile",
            thClass: "hidden",
          },
          {
            key: "has_attachment",
            label: "",
            class: "text-break w-80 clickable hidden-mobile",
            thClass: "w-80",
            sortable: false,
          },  
          {
            key: "subject",
            label: this.$t("SUBJECT"),
            class: "text-break clickable hidden-mobile",
            thClass: "hidden-mobile",
            sortable: false,
          },
          {
            key: "from_email",
            label: this.$t("FROM"),
            class: "text-break clickable hidden-sm",
            thClass: "hidden-mobile",
            sortable: false,
          },
          {
            key: "claimed",
            label: this.$t("CLAIMED_BY"),
            class: "text-break clickable hidden-sm",
            thClass: "hidden-mobile",
            sortable: false,
          },
          {
            key: "created_date",
            label: this.$t("CREATED"),
            class: "text-break w-date clickable hidden-mobile",
            thClass: "hidden-mobile",
            sortable: false,
          },
        ];
      }else{
        return [
          {
            key: "id",
            class: "hidden-not-mobile",
            thClass: "hidden",
          },
          {
            key: "has_attachment",
            label: "",
            class: "text-break w-80 clickable hidden-mobile",
            sortable: false,
          },
          {
            key: "subject",
            label: this.$t("SUBJECT"),
            class: "text-break clickable hidden-mobile",
            thClass: "hidden-mobile",
            sortable: false,
          },
          {
            key: "from_email",
            label: this.$t("FROM"),
            class: "text-break clickable hidden-sm",
            thClass: "hidden-mobile",
            sortable: false,
          },
          {
            key: "created_date",
            label: this.$t("CREATED"),
            class: "text-break w-date clickable hidden-mobile",
            thClass: "hidden-mobile",
            sortable: false,
          },
        ];

      }
    },
    showFilterClearAll() {
      if (this.functionbox_uuid == "") { 
        if (this.filter_archived.length > 0) {
          return true;
        }
      }
      if (this.functionbox_uuid != "") { 
        if (this.filter_assigned.length > 0) {
          return true;
        }
        if (this.filter_status.length > 0) {
          return true;
        }
      }
      if (this.filter_read.length > 0) {
        return true;
      }
      return false;
    },
  },
  watch: {
    filter_assigned: function (new_array) {
      this.HandleFilter(new_array, "message_inbox_assigned");
      this.getInbox();
    },
    filter_archived: function (new_array) {
      this.HandleFilter(new_array, "message_inbox_archived");
      this.getInbox();
    },
    filter_status: function (new_array) {
      this.HandleFilter(new_array, "message_inbox_status");
      this.getInbox();
    },
    filter_read: function (new_array) {
      this.HandleFilter(new_array, "message_inbox_read");
      this.getInbox();
    },
    update_message_uuid: function (new_message_uuid) {
      this.UpdateItem(new_message_uuid);
    },
    searchText: function () {
      if (this.searchText.length == 0) {
        this.getInbox();
      }
      if (this.searchText.length >= 2) {
        this.getInbox();
      }
    },
    limit: function () {
      this.getInbox();
    },
  },
  mounted: function () {
    this.filter_assigned = this.GetFilter("message_inbox_assigned",this.filter_assigned);
    this.filter_status = this.GetFilter("message_inbox_status", this.filter_status);
    this.filter_archived = this.GetFilter("message_inbox_archived", this.filter_archived);
    this.filter_read = this.GetFilter("message_inbox_read", this.filter_read);
    this.getInbox();
  },
};
</script>
<style></style>
